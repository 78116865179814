<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('tbm.请假申请')"
                 fixed
                 right-:text="$t('tbm.取消')"
                 @click-left="back"
                 @click-right="back">
    </van-nav-bar>
    <editComponent></editComponent>
  </div>
</template>
<script>
import editComponent from './components/editComponent';
export default {
  components: {
    editComponent
  },
  data () {
    return {
    }
  },
  methods: {
    back () {
      sessionStorage.removeItem('formData');
      this.$router.go(-1);
    },
  },
  created () {
  },
  beforeRouteLeave (to, from, next) {
    if (to.name == "approvalPanel") {
      next();
    } else {
      sessionStorage.removeItem('formData');
      next();
    }
  },
}
</script>
<style>
.van-picker.van-datetime-picker .van-hairline--top-bottom{
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 9;
}
</style>
